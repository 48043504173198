<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10128')}}
							</h2>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<!-- 아이디 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10129')}} <span class="dot dot--red"></span></h3>
									<div class="frms__wrap" :class="[isVerifiedEmail]" v-if="smsEmail === null">
										<input type="email" class="ipt-frm" :placeholder="t('10780')" @keyup="checkEmailFormat()" v-model="email" @keydown="handleEmail()"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn btn-medium btn-secondary" :disabled="!isEmail" :class="{active: isEmail}" @click="checkDuplicatedEmail">{{t('10130')}}</button>
										</div>
									</div>
									<div class="frms__wrap isVerifiedEmail" v-else>
										<input type="email" class="ipt-frm" :value="smsEmail" readonly>	
									</div>
									<div v-if="isVerifiedEmail === 'failed'" class="frms__msg">
										{{t('10131')}}
									</div>
								</div>
								<!-- 비밀번호 -->
								<div class="frms__item" v-if="smsEmail === null">
									<h3 class="frms__title">
										{{t('10132')}}
										<span class="dot dot--red"></span>
									</h3>
									<!-- [D] 통과할 경우 success 클래스 추가 -->
									<div class="frms__wrap" :class="{success: isInEnglish && isInNumber && isInLength}">
										<input :type="isShowPassword ? 'text' : 'password'" class="ipt-frm" :placeholder="t('10772')" maxlength="20" @keyup="checkPasswordFormat()" v-model="password"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-if="password.length > 0" @click="passwordReset()">
												<i class="icon icon-closesmall"></i>
											</button>
											<button type="button" class="btn-frmview" @click="toggleShowPassword()">
												<i class="icon icon-view" :class="{disabled: !isShowPassword}"></i>
											</button>
										</div>
									</div>
									<div class="frms__check">
										<!-- [D] 통과할 경우 success 클래스 추가 -->
										<span :class="{success: isInEnglish}">
											{{t('10133')}}
											<i class="icon icon-check--light"></i>
										</span>
										<span :class="{success: isInNumber}">
											{{t('10134')}}
											<i class="icon icon-check--light"></i>
										</span>
										<span :class="{success: isInLength}">
											{{t('10135')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
								</div>
								<!-- 비밀번호 입력시 닫기, 보기버튼 표시 -->
								<div class="frms__item" v-if="smsEmail === null">
									<h3 class="frms__title">
										{{t('10136')}}
										<span class="dot dot--red"></span>
									</h3>
									<div class="frms__wrap">
										<input :type="isShowPasswordConfirm ? 'text' : 'password'" class="ipt-frm" :placeholder="t('10781')" v-model="confirmPassword"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-if="confirmPassword.length > 0" @click="confirmPasswordReset()">
												<i class="icon icon-closesmall"></i>
											</button>
											<button type="button" class="btn-frmview" @click="toggleShowPasswordConfirm()">
												<i class="icon icon-view" :class="{disabled: !isShowPasswordConfirm}"></i>
											</button>
										</div>
									</div>
									<div class="frms__check">
										<!-- [D] 통과할 경우 success 클래스 추가 -->
										<span :class="{success: isSamePassword}">
											{{t('10137')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
								</div>
								<!-- 닉네임 확인 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10138')}} <span class="dot dot--red"></span></h3>
									<div class="frms__wrap" :class="[isVerifiedNickname]">
										<input type="text" class="ipt-frm" v-model="nickname" maxlength="6" @keydown="handleNickname()">
										<div class="frms__btns">
											<button 
												type="button" 
												class="btn btn-medium btn-secondary" 
												:class="{active: nickname.length > 0}" 
												:disabled="nickname.length < 2"
												@click="checkDuplicatedNickname()"
											>{{t('10139')}}</button>
										</div>
									</div>
									<div class="frms__check" v-if="isVerifiedNickname.length === 0">
										<span :class="{success: nickname.length >= 2}">
											{{t('10140')}}
											<i class="icon icon-check--light"></i>
										</span>
									</div>
									<div class="frms__msg" v-if="isVerifiedNickname === 'failed'">
										{{t('10141')}}
									</div>
								</div>
								<!-- 추천인 -->
								<div class="frms__item">
									<h3 class="frms__title">{{t('10142')}}</h3>
									<div class="frms__wrap" :class="[isVerifiedRecommendId]">
										<input type="text" class="ipt-frm" v-model="recommendId">
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-if="recommendId.length > 0" @click="recommendIdReset()">
												<i class="icon icon-closesmall"></i>
											</button>
											<button 
												type="button" 
												class="btn btn-medium btn-secondary" 
												:class="{active: recommendId.length > 0}" 
												:disabled="recommendId.length <= 0"
												@click="checkRecommendId()"
											>{{t('10143')}}</button>
										</div>
									</div>
									<div class="frms__msg" v-if="isVerifiedRecommendId === 'failed'">
										{{t('10144')}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<button type="button" class="btn" :class="complete ? 'btn-confirm' : 'btn-disabled'" @click="register()" :disabled="!complete">{{t('10145')}}</button>
				</div>
			</section>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import api from "@/urls/mmb01";
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 


export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const store = useStore();
		const router = useRouter();

 		const sessionId = computed(() => store.state.sessionId);
		const smsEmail = computed(() => store.state.mmb01.email);
		const snsJoinFlag = computed(() => store.state.mmb01.snsJoinFlag);
		const snsCi = computed(() => store.state.mmb01.snsCi);
		const snsNickname = computed(() => store.state.mmb01.snsNickname);
		const phoneNumber = computed(() => store.state.mmb01.phoneNumber);
		const email = ref("");
		const password = ref("");
		const confirmPassword = ref("");
		const nickname = ref(snsNickname.value ?? "");
		const code = ref("");
		const recommendId = ref("");
		
		const isEmail = ref(false);
		const isVerifiedEmail = ref("");
		const isVerifiedRecommendId = ref("");

		const isInEnglish = ref(false);
		const isInNumber = ref(false);
		const isInLength = ref(false);

		const isShowPassword = ref(false);
		const isShowPasswordConfirm = ref(false);

		const isVerifiedNickname = ref("");
		const openErrorAlert = ref(false)

		watch(recommendId, () => {
			isVerifiedRecommendId.value = "";
		});

		const checkDuplicatedEmail = async () => {
			const result = await api.postMmb0101({proc_cd: "01", data: email.value});	
			if(result.data.Res_cd === "0000") {
				isVerifiedEmail.value = "success";
			}else {
				isVerifiedEmail.value = "failed";
			}
		}

		const checkDuplicatedNickname = async () => {
			const result = await api.postMmb0101({proc_cd: "02", data: nickname.value});	
			if(result.data.Res_cd === "0000") {
				isVerifiedNickname.value = "success";
			}else {
				isVerifiedNickname.value = "failed";
			}
		}
		
		const checkRecommendId = async () => {
			const result = await api.postMmb0101({proc_cd: "03", data: recommendId.value});	
			if(result.data.Res_cd === "0000") {
				isVerifiedRecommendId.value = "success";
			}else {
				isVerifiedRecommendId.value = "failed";
			}
		}

		const checkEmailFormat = () => {
			if(email.value.toLowerCase().match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)){
				isEmail.value = true;
			}else {
				isEmail.value = false;
			}
		};

		const checkPasswordFormat = () => {
			if(password.value.match(/^(?=.*[a-zA-Z])/)){
				isInEnglish.value = true;
			}else {
				isInEnglish.value = false;
			}
			if(password.value.match(/^(?=.*[0-9])/)) {
				isInNumber.value = true;
			}else {
				isInNumber.value = false;
			}
			if(password.value.length >= 8 && password.value.length <= 20) {
				isInLength.value = true;
			}else {
				isInLength.value = false;
			}
		}

		const passwordReset = () => {
			password.value = "";
			checkPasswordFormat();
		}
		
		const confirmPasswordReset = () => {
			confirmPassword.value = "";
		}

		const toggleShowPassword = () => {
			isShowPassword.value = !isShowPassword.value;
		}

		const toggleShowPasswordConfirm = () => {
			isShowPasswordConfirm.value = !isShowPasswordConfirm.value;
		}

		const isSamePassword = computed(() => {
			return password.value === confirmPassword.value && confirmPassword.value !== "";
		});

		const handleEmail = () => {
			if(isVerifiedEmail.value.length !== 0) {
				isVerifiedEmail.value = "";
			}
		}
		
		
		const handleNickname = () => {
			if(isVerifiedNickname.value.length !== 0) {
				isVerifiedNickname.value = "";
			}
		}
		
		const login = async (email) => {
			const ip = await fetch('https://api64.ipify.org').then(res=>res.text());

			const data = await store.dispatch("mmb01/fetchMmb01", {
				mem_id: email,
				passwd: smsEmail.value === null ? password.value : undefined,
				sessionid: sessionId.value,
				ip_addr: ip,
				sns_join_flag: snsJoinFlag.value,
				sns_ci: snsCi.value,
			});
			if(data.Res_cd === "0000" && data.Res_data.length > 0){
				store.commit("SET_USERDATA", {userData: data.Res_data[0]});
				router.push("/join/complete");
			} else {
				console.error("로그인 실패")
			}
		}

		const register = async () => {
			const data = await api.postMmb0110({
				mem_id: smsEmail.value === null ? email.value : smsEmail.value,
				nick_nm: nickname.value,
				passwd: smsEmail.value === null ? password.value : undefined,
				recomm_id: recommendId.value.length === 0 ? undefined : recommendId.value,
				hp_no: phoneNumber.value.replace(/-/gi, ""),
				sns_join_flag: snsJoinFlag.value,
				sns_ci: snsCi.value,
			});
			if(data.data.Res_cd === "0000"){
				alert(data.data.Res_msg);
				login(smsEmail.value === null ? email.value : smsEmail.value);
			} else {
				if(data.data.Res_cd === '-1'){
					//alert(data.data.Res_msg);
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: data.data.Res_msg});
					openErrorAlert.value = true;

					router.go('/layers/login')
				}
				console.error("회원가입 실패")
			}
		}
		const { t }= useI18n() //번역필수 모듈

		return {
			email,
			password,
			nickname,
			code,
			isEmail,
			snsNickname,
			checkDuplicatedEmail,
			checkEmailFormat,
			isVerifiedEmail,
			checkPasswordFormat,
			isInEnglish,
			isInNumber,
			isInLength,
			passwordReset,
			isShowPassword,
			toggleShowPassword,
			confirmPassword,
			isShowPasswordConfirm,
			toggleShowPasswordConfirm,
			confirmPasswordReset,
			isSamePassword,
			checkDuplicatedNickname,
			isVerifiedNickname,
			checkRecommendId,
			isVerifiedRecommendId,
			handleEmail,
			handleNickname,
			complete: computed(() => {
				return ((isVerifiedEmail.value === "success" &&
				password.value === confirmPassword.value && confirmPassword.value !== "") || smsEmail.value !== null) &&
				isVerifiedNickname.value === "success"
			}),
			recommendId,
			register,
			smsEmail,
			recommendIdReset: () => {
				recommendId.value = "";
			},
			goBack: () => {
				router.back();
			},
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,
			i18n,
		}
	},
}
</script>
